import Vue from 'vue'
export const essilorPatientOut = async (attendance, clinicId) => {
  if (attendance?.essilor_room_id && attendance?.essilor_room_id !== null) {
    try {
      await Vue.prototype.api.essilorPatientOut({
        essilorRoomId: attendance.essilor_room_id, 
        clinic_id: attendance.clinic.id,
        attendance_id: attendance.id,
        room_id: attendance.essilor_room_id
      })
    } catch (err) {
      Vue.prototype.$toast.error(err.message)
    }
  }
}